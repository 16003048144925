import { useEffect, useState } from "react";
import "../Blogs/blogs.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";
import useAxios from "../../customHooks/useAxios";
import { useParams } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const BlogView = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const { slug } = useParams();

  const {
    response: blogsResponse,
    loading: blogsLoading,
    error: blogsError,
    operation: blogsOperation,
  } = useAxios();
  const {
    response: blogResponse,
    loading: blogLoading,
    error: blogError,
    operation: blogOperation,
  } = useAxios();

  useEffect(() => {
    blogsOperation({
      method: "get",
      url: "/blogs",
    });
  }, []);

  useEffect(() => {
    blogOperation({
      method: "get",
      url: "/blogs",
      params: {
        slug: slug,
      },
    });
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const scrollPercentage =
        (scrollPosition / (documentHeight - windowHeight)) * 100;

      if (scrollPercentage > 90) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");

  return (
    <>
          {!isMobileDevice && showNavbar && (
        <div className="blog-navbar">
          <p>Follow Us</p>
          <div className="postman-socialmedia-icons">
            <a
              href="https://instagram.com/zippeeindia?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram
                style={{
                  color: "#705446",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/zippee/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn
                style={{
                  color: "#705446",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              />
            </a>
            <a
              href="https://twitter.com/zippeeindia"
              target="_blank"
              rel="noreferrer"
            >
              <FaXTwitter style={{ color: "#705446", fontSize: "15px" }} />
            </a>
          </div>
        </div>
      )}
      <div className="main blog-pages">
        <div className="blogs-view-page">
          <Box>
            <div className="sub-menu">
              <span>
                <Link to="/">
                  <p>Home</p>
                </Link>
              </span>
              <span style={{ marginTop: "-2px" }}>
                <KeyboardArrowRightIcon />
              </span>
              <span>
                <Link to="/blogs">
                  <p>Blogs</p>
                </Link>
              </span>
              <span style={{ marginTop: "-2px" }}>
                <KeyboardArrowRightIcon />
              </span>
              <span>
                <p style={{ color: "#705446" }}>{slug}</p>
              </span>
            </div>

            <Grid>
              {blogResponse
                ? blogResponse?.data?.blog?.map((item, index) => (
                    <Grid>
                      <Typography
                        variant="h1"
                        className="Text bold-40 text-stroke-navy-color black"
                        style={{
                          fontWeight: "800",
                          fontSize: "40px",
                          textAlign: "start",
                          marginBottom: "40px",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                  ))
                : null}

              <Grid container>
                <Grid item xs={12} md={9}>
                  {blogResponse
                    ? blogResponse?.data?.blog?.map((item, index) => (
                        <Grid item xs={12} key={index}>
                          <div className="blog-details">
                            <span>{item.author__name}</span>
                            <span class="vertical-line"></span>
                            <span>{item.date}</span>
                            <span class="vertical-line"></span>
                            <span>1 min read</span>
                          </div>
                          <div>
                            <img
                              className="blog-image"
                              src={
                                "https://zippee-delivery-assets.s3.amazonaws.com/" +
                                item.image
                              }
                              alt="blog-img"
                            />

                            <p className="blog-description">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></div>
                            </p>
                          </div>

                          <div className="cta-card-view">
                            <p
                              class="request-access-button"
                              style={{ fontSize: "20px" }}
                            >
                              Excited to get started?{" "}
                              <Link
                                to="https://www.zippee.delivery/"
                                target="_blank"
                              >
                                REQUEST ACCESS
                              </Link>{" "}
                              to join our waitlist & learn more.
                            </p>
                          </div>
                        </Grid>
                      ))
                    : null}

                  <div
                    className="cta-card-view"
                    style={{
                      backgroundImage:
                        "linear-gradient(to bottom, #ffffff 0%, #f7cc60 100%)",
                    }}
                  >
                    <Grid>
                      <div
                        style={{
                          // display: "flex",
                          // justifyContent: "space-evenly",
                        }}
                      >
                        <p style={{ fontSize: "20px" }}>
                          Liked what you read? Share with your team
                        </p>
                        <Typography className="postman-socialmedia-icons">
                          <a
                            href="https://instagram.com/zippeeindia?igshid=MzRlODBiNWFlZA=="
                            target="_blank"
                            rel="noreferrer"
                          >
                            <BsInstagram
                              style={{ color: "red", fontSize: "23px" }}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/zippee/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaLinkedinIn
                              style={{ color: "#0a66c2", fontSize: "25px" }}
                            />
                          </a>
                          <a
                            href="https://twitter.com/zippeeindia"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaXTwitter style={{ fontSize: "20px" }} />
                          </a>
                          <a
                            href="https://wa.me/+919650062127"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RiWhatsappFill
                              style={{ color: "green", fontSize: "25px" }}
                            />
                          </a>
                        </Typography>
                      </div>
                    </Grid>
                  </div>
                </Grid>
                <Grid className="suggested-blogs" item xs={12} md={3}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <div className="suggested-posts-header">
                        <h2>Suggested Posts</h2>
                      </div>
                    </Grid>
                    {blogsResponse
                      ? blogsResponse.data.blog.map((item, index) =>
                          item.slug !== slug ? (
                            <Grid item xs={12} key={index}>
                              <Card
                                className="blog-card"
                                sx={{ maxWidth: 500 }}
                              >
                                <CardActionArea>
                                  <Link to={`/blogs/${item.slug}`}>
                                    <CardMedia
                                      component="img"
                                      height="180"
                                      image={
                                        "https://zippee-delivery-assets.s3.amazonaws.com/" +
                                        item.image
                                      }
                                      alt="green iguana"
                                    />
                                  </Link>
                                  <CardContent>
                                    <Typography
                                      className="blog-title-name"
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                      style={{
                                        background: "#F4C244",
                                        color: "#705446",
                                        width: "fit-content",
                                        borderRadius: "5px",
                                        padding: "2px 5px 2px 5px",
                                      }}
                                    >
                                      {item.sub_category__category__title}
                                    </Typography>
                                    <Typography className="blog-sub">
                                      {item?.title?.length > 45
                                        ? item?.title?.slice(0, 45) + "..."
                                        : item.title}
                                    </Typography>
                                    <div className="blog-card-footer">
                                      <Typography className="blog-date">
                                        <CalendarMonthIcon />{" "}
                                        <span
                                          style={{
                                            marginTop: "5px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {item.date}
                                        </span>
                                      </Typography>
                                      <Typography
                                        className="blog-socialmedia-icons"
                                        style={{
                                          fontSize: "10px",
                                          color: "black",
                                        }}
                                      >
                                        <a
                                          href="https://instagram.com/zippeeindia?igshid=MzRlODBiNWFlZA=="
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <BsInstagram
                                            style={{
                                              color: "red",
                                              fontSize: "17px",
                                            }}
                                          />
                                        </a>
                                        <a
                                          href="https://www.linkedin.com/company/zippee/"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FaLinkedinIn
                                            style={{
                                              color: "#0a66c2",
                                              fontSize: "17px",
                                            }}
                                          />
                                        </a>
                                        <a
                                          href="https://twitter.com/zippeeindia"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FaXTwitter
                                            style={{ fontSize: "17px" }}
                                          />
                                        </a>
                                        <a
                                          href="https://wa.me/+919650062127"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <RiWhatsappFill
                                            style={{
                                              color: "green",
                                              fontSize: "17px",
                                            }}
                                          />
                                        </a>
                                      </Typography>
                                    </div>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          ) : null
                        )
                      : null}
                    {blogsLoading ? (
                      <Grid item xs={12} key={1}>
                        <Skeleton
                          variant="rectangular"
                          style={{ width: "100%", height: "300px" }}
                        />
                        <Skeleton
                          variant="rectangular"
                          style={{
                            width: "100%",
                            height: "300px",
                            marginTop: "25px",
                            marginBottom: "80px",
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default BlogView;

import React from "react";
import "./about.css";
import { TypeAnimation } from "react-type-animation";

// importing common components
import TextField from "../../CommonComponents/textfield";

// importing components
import Description from "./components/description";
import Investors from "./components/investors";
import Team from "./components/team";
import Values from "./components/values";

const About = () => {
  return (
    <>
      <div className="about-page">
        <div className="about-title">
          <TextField color="black" appearance="bold-40">
            Building Next-Gen
          </TextField>
          <TypeAnimation
            sequence={["", "Fulfillment Infrastructure for India ", 2000]}
            wrapper="span"
            cursor={true}
            deletionSpeed={{ type: "keyStrokeDelayInMs", value: 20 }}
            speed={{ type: "keyStrokeDelayInMs", value: 20 }}
            repeat={Infinity}
            style={{
              display: "inline-block",
            }}
            className="bold-40 secondary-color about-page-title-animation"
          />
        </div>

        <Description />
        <Investors />
        <Team />
        <Values />
      </div>
    </>
  );
};

export default About;

import './404.css'
import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

export default function ErrorPage() {

    useEffect(() => {
        const header = document.querySelector('.navbar');
        header.classList.add('hide');

        const footer = document.querySelector('.footer');
        footer.classList.add('hide');

        const chat = document.querySelector('.Whatsapp-Button-Container');
        chat.classList.add('hide');

        const body = document.body;
        body.classList.add('error-page-body');
    }, [])

  return (
    <div>
        <h1 className="error-page-h1">404</h1>
        <p className="error-page-p">Oops! Something is wrong.</p>
        <div className='error-page-home-button'>
        <Link to="/" className="error-page-button" >
            <i class="icon-home"></i>
            Home
        </Link>
        </div>
    </div>
  );
};

import React from "react";
import Text from "../../../CommonComponents/text";
import images from "../../../helpers/ImportImages";

import Card from "../../../Components/Cards/Card";
import { Link } from "react-router-dom";
import TextField from "../../../CommonComponents/textfield";
import "../about.css";
import CustomButton from "../../../CommonComponents/CustomButton";

const Values = () => {
  return (
    <div className="container">
      <Text text="Zippee Values" appearance="bold-40" color="black" />
      <div className="values-cards">
        <Card
          title="Empathy, Integrity, & Transparency First"
          effects="invert-background value-icons"
          img_src={images["EmpathyIcon.svg"]}
          title_appearance="text-align-center"
          img_class="black-filter"
        />
        <Card
          title="Partners, not Clients"
          effects="invert-background value-icons"
          img_src={images["PartnersIcon.svg"]}
          title_appearance="text-align-center"
          img_class="black-filter"
        />
        <Card
          title="Persevere with Determination & Passion"
          effects="invert-background value-icons"
          img_src={images["DeterminationIcon.svg"]}
          title_appearance="text-align-center"
          img_class="black-filter"
        />
        <Card
          title="Act like Owners, not employees"
          effects="invert-background value-icons"
          img_src={images["OwnersIcon.svg"]}
          title_appearance="text-align-center"
          img_class="black-filter"
        />
        <Card
          title="Move quick, but Learn Quicker"
          effects="invert-background value-icons"
          img_src={images["QuickIcon.svg"]}
          img_class="black-filter"
        />
      </div>
      <div className="founder-quote">
        <Text
          color="black"
          appearance="bold-30 text-align-center"
          text="“We’re LONG people & have begun scratching the surface of a $100 billion opportunity to empower consumer businesses in the delivery era. We commit to creating a diverse, inclusive tribe of E-commerce enthusiasts while providing them with the tools, mindsets, & perspectives to learn & grow. Join us on this challenging, yet rewarding startup journey.”"
        />
        <Text
          text="- Madhav Kasturia, founder"
          appearance="bold-20"
          color="black"
        />
      </div>
      <Link
        to="https://zfwindia.notion.site/ZFW-Careers-06c01cf4e6cb48adac0bf98360346805"
        target="_blank"
      >
        <CustomButton
          variant="primary see-open-roles-btn"
          styles={{ width: "300px" }}
        >
          <TextField appearance="bold-20" color="black">
            SEE OPEN ROLES
          </TextField>
        </CustomButton>
      </Link>
    </div>
  );
};

export default Values;
